<template>
	<v-container>
		<v-card class="mx-auto my-auto mt-16" max-width="600">
			<v-card-title>{{ $t('pageNotFound') }}</v-card-title>

			<v-card-actions>
				<v-btn text color="orange" @click="goBack">
					{{ $t('back') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
export default {
	methods: {
		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>

<style lang="scss" scoped></style>
